<template>
	<div>
		<div class="row">
	  	<div class="col-md-12 mr-auto ml-auto" v-if="costRegistrationLoaded">
				<card :title="$t('sidebar.costReport')">
					<base-input v-if="!showProjectDetails">
						<el-input
							type="search"
							class="mb-3 search-input from-group"
							clearable
							prefix-icon="el-icon-search"
							:placeholder="$t('common.searchRecords')"
							v-model="searchQuery"
							aria-controls="datatables"
						>
						</el-input>
					</base-input>
					<div class="calendar d-flex align-items-center">
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="fromDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
						<span class="mx-2">-</span>
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="toDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
					</div>
					<template>
						<div v-if="!showProjectDetails">
							<div v-if="!isMobile">
								<el-table :data="groupedReservations.projectGroups" @row-click="expandProject">
									<el-table-column prop="projectName" :label="$t('common.project')" min-width="150"/>
									<el-table-column prop="totalAmount" :label="$t('common.totalAmount')" min-width="50">
										<template slot-scope="scope">
											{{ formatNumber(scope.row.totalAmount) }}
										</template>
									</el-table-column>
									<el-table-column prop="totalAmountWithVAT" :label="$t('common.totalAmountVAT')" min-width="50">
										<template slot-scope="scope">
											{{ formatNumber(scope.row.totalAmountWithVAT) }}
										</template>
									</el-table-column>
								</el-table>
								<el-table v-if="groupedReservationsCalculated" :data="groupedReservations">
									<el-table-column :label="$t('common.totalAmount')" min-width="150" />
									<el-table-column :label="formatNumber(groupedReservations.totalAmount)" min-width="50" />
									<el-table-column :label="formatNumber(groupedReservations.totalAmountWithVAT)" min-width="50"/>
								</el-table>
							</div>
							<div v-else>
								<el-table :data="groupedReservations.projectGroups" @row-click="expandProject" >
									<el-table-column :label="$t('common.info')" min-width="150">
										<template slot-scope="scope">
											<div>
												<div><strong>{{$t('common.project')}}:</strong> {{ scope.row.projectName }}</div>
												<div><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalAmount) }}</div>
												<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalAmountWithVAT) }}</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(groupedReservations.totalAmount) }}</div>
								<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(groupedReservations.totalAmountWithVAT) }}</div>
							</div>
						</div>
						<div v-if="showProjectDetails">
							<card :title="selectedProject.projectName" :onCancel="collapseProject">
								<div class="project-header">
									<h5>{{ $t('common.totalAmount') }} : {{ formatNumber(selectedProject.totalAmount) }} kr</h5>
									<h5>{{ $t('common.totalAmountVAT') }} : {{ formatNumber(selectedProject.totalAmountWithVAT) }} kr</h5>
								</div>
								<el-table v-if="!isMobile" :data="selectedProject.reservations">
									<el-table-column :label="$t('tools.tools')">
										<template slot-scope="scope">
											#{{scope.row.tool.customerToolId}} {{scope.row.tool.name}}
										</template>
									</el-table-column>
									<el-table-column 
										:label="$t('tools.reservation')"
									>
										<template slot-scope="scope">
											<div>
												{{ setupDateRange(scope.row.reservedTimestamp, scope.row.unreservedTimestamp) }}
											</div>
											<div v-if="scope.row.rented">
												{{ $t('tools.rented') }}: {{ scope.row.externalUser }}
											</div>
										</template>
									</el-table-column>
									<el-table-column :min-width="60" :max-width="60" prop="dailyPrice" :label="$t('tools.dailyPrice')">
										<template slot-scope="scope">
											<div>{{ formatNumber(scope.row.dailyPrice) }} </div>
											<div v-if="scope.row.quantity !== null">{{$t('tools.quantity')}}: {{ scope.row.quantity }}</div>
										</template>
									</el-table-column>
									<el-table-column :label="$t('common.amount')">
										<template slot-scope="scope">
											<div v-if="scope.row.unreservedTimestamp === null" class="text-info">{{ formatNumber(scope.row.totalPrice) }} ({{$t('tools.reserved')}})</div>
											<div v-else-if="!scope.row.totalPriceOverridden">{{ formatNumber(scope.row.totalPrice) }}</div>
											<div v-else class="text-danger">{{ formatNumber(scope.row.totalPrice) }} ({{$t('common.updated')}})</div>
										</template>
									</el-table-column>
									<el-table-column :label="$t('common.amountVAT')">
										<template slot-scope="scope">
											<div v-if="scope.row.unreservedTimestamp === null" class="text-info">{{ formatNumber(scope.row.totalPriceWithVAT) }} ({{$t('tools.reserved')}})</div>
											<div v-else-if="!scope.row.totalPriceOverridden">{{ formatNumber(scope.row.totalPriceWithVAT) }}</div>
											<div v-else class="text-danger">{{ formatNumber(scope.row.totalPriceWithVAT) }} ({{$t('common.updated')}})</div>
										</template>
									</el-table-column>
									<el-table-column :min-width="40" :max-width="40" align="right" :label="$t('common.actions')">
										<div slot-scope="props">
											<base-button v-if="props.row.unreservedTimestamp !== null" @click.native.stop="handleReservationEdit(props.$index, props.row)" class="edit btn-link" type="warning" size="sm" icon>
												<i class="tim-icons icon-pencil"></i>
											</base-button>
											<base-button v-else
												@click.native.stop="handleInfoPopup(props.$index, props.row)"
												class="edit btn-link"
												type="info"
												size="sm"
												icon
											>
												<i class="tim-icons icon-alert-circle-exc"></i>
											</base-button>
										</div>
									</el-table-column>
								</el-table>
								<el-table v-else :data="selectedProject.reservations">
									<el-table-column :label="$t('common.info')" min-width="150">
										<template slot-scope="scope">
											<div>
												<div><strong>{{$t('tools.tools')}}:</strong> #{{scope.row.tool.customerToolId}} {{scope.row.tool.name}}</div>
												<div><strong>{{$t('tools.reservation')}}:</strong> {{ setupDateRange(scope.row.reservedTimestamp, scope.row.unreservedTimestamp) }} </div>
												<div v-if="scope.row.rented"><strong>{{$t('tools.rentedTo')}}:</strong> {{ scope.row.externalUser }}</div>
												<div><strong>{{$t('tools.dailyPrice')}}:</strong> {{ formatNumber(scope.row.dailyPrice) }}</div>
												<div v-if="scope.row.quantity !== null"><strong>{{$t('tools.quantity')}}:</strong> {{ scope.row.quantity }}</div>
												<template v-if="!scope.row.totalPriceOverridden">
													<div><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}</div>
													<div><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}</div>
												</template>
												<template v-else-if="scope.row.unreservedTimestamp === null">
													<div class="text-info"><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}*</div>
													<div class="text-info"><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}*</div>
													<div class="text-info"><strong>{{$t('common.updated')}}:</strong> {{$t('common.yes')}} </div>
												</template>
												<template v-else>
													<div class="text-danger"><strong>{{$t('common.totalAmount')}}:</strong> {{ formatNumber(scope.row.totalPrice) }}*</div>
													<div class="text-danger"><strong>{{$t('common.totalAmountVAT')}}:</strong> {{ formatNumber(scope.row.totalPriceWithVAT) }}*</div>
													<div class="text-danger"><strong>{{$t('common.updated')}}:</strong> {{$t('common.yes')}} </div>
												</template>
												<div>
													<base-button v-if="scope.row.unreservedTimestamp !== null"
														@click.native.stop="handleReservationEdit(scope.$index, scope.row)"
														class="edit btn-link"
														type="warning"
														size="sm"
														icon
													>
														<i class="tim-icons icon-pencil"></i>
													</base-button>
													<base-button v-else
														@click.native.stop="handleInfoPopup(scope.$index, scope.row)"
														class="edit btn-link"
														type="info"
														size="sm"
														icon
													>
														<i class="tim-icons icon-alert-circle-exc"></i>
													</base-button>
												</div>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<base-button class="download-button" type="standard" @click="generatePDF(selectedProject)">
									{{ $t('common.download') }}
								</base-button>
							</card>
						</div>
					</template>
					<modal :show.sync="showUpdateReservationDaysModal" class="white-content">
					<h3 class="card-title">{{$t('tools.updateReservation')}}</h3>
					<div class="row">
						<div class="col-12">
						<label>{{$t('common.changeDates')}}</label>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
						<div class="calendar d-flex align-items-center">
							<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="updatedReservationDate"
								@change="updateTotalPrice"
							>
							</el-date-picker>
							</div>
							<span class="mx-2">-</span>
							<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								format="dd/MM/yyyy"
								value-format="yyyy-MM-dd"
								v-model="updatedUnreservationDate"
								@change="updateTotalPrice"
							>
							</el-date-picker>
							</div>
						</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
						<base-checkbox v-model="updatePrice">
							{{$t('common.updateTotalAmountVAT')}}
						</base-checkbox>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
						<base-input
							:disabled="!updatePrice"
							:placeholder="$t('common.totalAmountVAT')"
							v-model="updatedTotalPrice"
						>
						</base-input>
						</div>
					</div>
					<div class="row">
						<div class="col-sm d-flex justify-content-end">
						<base-button @click="handleReservationUpdate" type="standard" fill>
							{{$t('common.submit')}}
						</base-button>
						</div>
					</div>
					</modal>
				</card>
			</div>
		</div>
	</div>
</template>
  
<script>

import api from "../../services/api"
import { Table, TableColumn, DatePicker } from 'element-ui';
import { Modal } from 'src/components';
import { jsPDF } from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import swal from 'sweetalert2';

export default {
	components: {
		[DatePicker.name]: DatePicker,
		[Table.name]: Table,
    [TableColumn.name]: TableColumn,
		Modal
	},
	data() {
		return {
			activeNames: ['1'],
			toDate: null,
			fromDate: null,
			updatedReservationDate: null,
			updatedUnreservationDate: null,
			updatedTotalPrice: null,
			dailyPriceForUpdate: null,
			quantityForUpdate: null,
			groupedProjects: [],
			reservationData: [],
			selectedProjectId: null,
			customerInformation: {},
			costRegistrationLoaded: false,
			groupedReservationsCalculated: false,
			searchQuery: '',
			isMobile: window.innerWidth <= 768,
			showProjectDetails: false,
			showUpdateReservationDaysModal: false,
			updateDays: '',
			latestUnreservedId: '',
			latestUnreservedEntity: 'ToolReservation',
			expandedProject: null,
			activeIndex: '',
			updatePrice: false,
		};
	},
	computed: {
		groupedReservations() {
			const grouped = this.reservationData;
			const project = grouped.projectGroups.find(group => group.projectName === 'RentProject');
			if (project) {
				project.projectName = this.$t('tools.rented');
			}

			// Sort the project groups
			let sortedGrouped = grouped.projectGroups.slice().sort((a, b) => {
				if (a.projectName === this.$t('tools.rented')) return 1;
				if (b.projectName === this.$t('tools.rented')) return -1;
				return a.projectName.localeCompare(b.projectName);
			});

			// Filter based on search query
			if (this.searchQuery) {
				sortedGrouped = sortedGrouped.filter(group => {
					return group.projectName && group.projectName.toLowerCase().includes(this.searchQuery.toLowerCase());
				});
			}
			this.groupedReservationsCalculated = true;

			return {
				...grouped,
				projectGroups: sortedGrouped
			};
		},
		selectedProject() {
			const project = this.groupedReservations.projectGroups.find(
				group => group.projectId === this.selectedProjectId
			);

			return project;
		},
		selectEditClass() {
			let darkMode = localStorage.getItem('darkMode');
			// If darkMode does not exist in localStorage, default it to 'false'
			if (darkMode === null) {
				darkMode = 'false';
			}
			if (darkMode === 'false') {
				return 'btn-standard';
			} else {
				return 'btn-primary';
			}
		}
	},
	created() {
		window.addEventListener('resize', this.updateIsMobile);
		this.initializeDateRange();
		this.fetchCostRegistration();
		this.fetchCustomerInformation();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateIsMobile);
	},
	methods: {
		handleInfoPopup() {
			this.$notify({
					message: this.$t('common.unableToEditActiveReservation'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "info",
					timeout: 0
				});
				return;
		},
		handleReservationEdit(index, row) {
			var emptyUnreservePlaceHolder = 'X';
			swal.fire({
				title: this.$t('common.reservation'),
				html: `${this.$t('tools.toolReserved')} ${new Date(row.reservedTimestamp).toLocaleDateString('is-IS')} - ${row.unreservedTimestamp != null ? new Date(row.unreservedTimestamp).toLocaleDateString('is-IS') : emptyUnreservePlaceHolder} <br/> ${this.$t('common.totalAmount')} ${this.formatNumber(row.totalPriceWithVAT)} Kr.`,
				icon: 'success',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-danger btn-fill',
					cancelButton: 'btn btn-success btn-fill'
				},
				confirmButtonText: this.$t('tools.updateReservation'),
				cancelButtonText: this.$t('delete.cancel'),
				buttonsStyling: false
			}).then(result => {
				if (result.value) {
					this.latestUnreservedId = row.id;
					this.latestUnreservedEntity = row.rented ? 'ExternalReservation' : 'ToolReservation';
					this.updatedReservationDate = this.formatDate(row.reservedTimestamp);
					this.updatedUnreservationDate =  this.formatDate(row.unreservedTimestamp) ?? this.toDate;
					this.updatedTotalPrice = row.totalPriceWithVAT;
					this.dailyPriceForUpdate = row.dailyPrice;
					this.quantityForUpdate = row.quantity ?? 1;
					this.showUpdateReservationDaysModal = true;
					this.activeIndex = index
				}
			});
		},
		handleReservationUpdate(){
			if (!this.isValid()) {
				return;
			}

			// Default the URL value to standard tool reservation
			let url = `/Tool/Reservation/${this.latestUnreservedId}`;
			
			if (this.latestUnreservedEntity === 'ExternalReservation') {
				url = `/Tool/Reservation/External/${this.latestUnreservedId}`;
			}
			api.put(url,{updatedReservedTimestamp: this.updatedReservationDate, updatedUnreservedTimestamp: this.updatedUnreservationDate, totalPrice: this.updatedTotalPrice}).then(response => {
				this.showUpdateReservationDaysModal = false;
				this.updatePrice = false;
				this.updatedReservationDate = null;
				this.updatedUnreservationDate = null;
				this.dailyPriceForUpdate = null;
				this.quantityForUpdate = null;
				swal.fire({
					title: `${this.$t('tools.updatedPrice')} ${response.data.totalPrice} Kr.`,
					icon: 'success',
					customClass: {
						confirmButton: 'btn btn-success btn-fill'
					},
					buttonsStyling: false
				});
			})
			.catch(error => {
				console.error('API request error:', error);
				this.$notify({
					message: `${this.$t('tools.toolReturnError')}: ${error.message}`,
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
			})
			.finally(() => {
				this.fetchCostRegistration();
			});
		},
		updateTotalPrice() {
			const startDate = new Date(this.updatedReservationDate);
			const endDate = new Date(this.updatedUnreservationDate);
			if (startDate > endDate) {
				this.$notify({
					message: this.$t('errors.startDateBiggerThanEndDate'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return;
			}

			if (endDate === null || startDate === null) {
				this.updatedTotalPrice = 0;
				return;
			}

			// Normalize the dates to midnight (00:00:00)
			startDate.setHours(0, 0, 0, 0);
			endDate.setHours(0, 0, 0, 0);

			// Calculate the difference in time (milliseconds)
			const timeDiff = endDate.getTime() - startDate.getTime();

			// Calculate the total number of days
			const totalDays = timeDiff / (1000 * 3600 * 24) + 1;

			// Round up to the nearest whole day
			const totalDaysRounded = Math.ceil(totalDays);

			this.updatedTotalPrice = Math.ceil(totalDaysRounded * this.dailyPriceForUpdate * this.quantityForUpdate);
		},
		isValid() {
			if (this.updatedReservationDate === null || this.updatedReservationDate === '' || this.updatedUnreservationDate === null || this.updatedUnreservationDate === '') {
          this.$notify({
            message: this.$t('errors.datesEmptyOrIncorrect'),
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "danger",
            timeout: 0
          });
          return false;
        }

			if (!this.isValidDate(this.updatedReservationDate) || !this.isValidDate(this.updatedUnreservationDate)) {
				this.$notify({
					message: this.$t('errors.datesEmptyOrIncorrect'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return false;
			}

			if (new Date(this.updatedReservationDate) > new Date(this.updatedUnreservationDate)) {
				this.$notify({
					message: this.$t('errors.startDateBiggerThanEndDate'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0
				});
				return false;
			}

			if (!Number.isInteger(Number(this.updatedTotalPrice)) || this.updatedTotalPrice === '') {
				this.$notify({
					message: this.$t('common.needsToBeNumber'),
					icon: "tim-icons icon-alert-circle-exc",
					horizontalAlign: "center",
					verticalAlign: "top",
					type: "danger",
					timeout: 0,
				});
				return false;
			}
			return true;
		},
		isValidDate(dateString) {
			const date = new Date(dateString);
			return date instanceof Date && !isNaN(date);
		},
		formatNumber(num) {
			if (num === null || num === undefined) return '';
			
			const roundedNumber = Math.round(num);
			const fixedNumber = roundedNumber.toFixed(2);
			const parts = fixedNumber.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return parts.join(".");
		},
		expandProject(row) {
			this.selectedProjectId = row.projectId;
			this.expandedProject = row;
			this.showProjectDetails = true;
			window.scrollTo(0, 0);
		},
		collapseProject() {
			this.fetchCostRegistration();
			this.expandedProject = null;
			this.selectedProjectId = null;
			this.showProjectDetails = false;
		},
		initializeDateRange() {
			const today = new Date();
			this.toDate = this.formatDate(today);
			this.fromDate = this.formatDate(new Date(today.getFullYear(), today.getMonth(), 1));
		},
		formatDate(date) {
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${year}-${month}-${day}`;
		},
		fetchCostRegistration() {
			const costReportUrl = `/CostReport/Reservations?fromDate=${this.fromDate}&toDate=${this.toDate}`;
			api.get(costReportUrl)
				.then(response => {
					this.reservationData = response.data;
					this.costRegistrationLoaded = true;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		setupDateRange(resTimestamp, unresTimestamp) {
			var dateDisplay = resTimestamp < this.fromDate ? this.formatDateDisplay(this.fromDate) : this.formatDateDisplay(resTimestamp);
			dateDisplay += unresTimestamp <= this.toDate ? ` - ${this.formatDateDisplay(unresTimestamp) }` : ` - ${this.formatDateDisplay(this.toDate) }`
			return dateDisplay;
		},
		formatDateDisplay(date) {
			//This is used when displaying the reservation period after you select a project
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${day}/${month}/${year}`;
		},
		fetchCustomerInformation() {
			let customerUrl = `/Customers/Me`
			api.get(customerUrl)
				.then(response => {
					this.customerInformation = response.data.value;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		generatePDF(project) {
			const doc = new jsPDF();
			doc.setFont('times', 'normal');
			const textXPosition = 65;

			// This whole if else if crap should be revisited, is a bit retarded how I am doing this now tbh
			if (this.customerInformation.logoBytes === null && this.customerInformation.identificationNumber === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(project.projectName, textXPosition, 30);
				doc.text(project.projectKey, textXPosition, 40);
				generatePdfBody(project, doc);
			}
			else if (this.customerInformation.logoBytes === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
				doc.setFontSize(10);
				doc.text(project.projectName, textXPosition, 40);
				doc.text(project.projectKey, textXPosition, 50);
				this.generatePdfBody(project, doc);
			}
			else if (this.customerInformation.identificationNumber === null) {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					const targetSize = 50;
					const imgWidth = img.width;
					const imgHeight = img.height;
					// Calculate the scaling factor to maintain aspect ratio
					const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// Calculate the new width and height to maintain aspect ratio 
					const width = imgWidth * scale;
					const height = imgHeight * scale; 
					
					// Calculate the positions to center the image if it's smaller than 50x50 
					const xOffset = (targetSize - width) / 2;
					const yOffset = (targetSize - height) / 2;
					// Add the image with the calculated dimensions, centered within the 50x50 box 
					doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(project.projectName, textXPosition, 30);
					doc.text(project.projectKey, textXPosition, 40);
					this.generatePdfBody(project, doc);
				}
				img.onerror = (e) => { 
					console.error("Image failed to load");
					// Display an error message to the user 
					this.$notify({
						message: ` ${this.$t('errors.companyPhoto')}`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				};
			}
			else {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					const targetSize = 50;
					const imgWidth = img.width;
					const imgHeight = img.height;
					// Calculate the scaling factor to maintain aspect ratio
					const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// Calculate the new width and height to maintain aspect ratio 
					const width = imgWidth * scale;
					const height = imgHeight * scale; 
					
					// Calculate the positions to center the image if it's smaller than 50x50 
					const xOffset = (targetSize - width) / 2;
					const yOffset = (targetSize - height) / 2;
					// Add the image with the calculated dimensions, centered within the 50x50 box 
					doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
					doc.setFontSize(10);
					doc.text(project.projectName, textXPosition, 40);
					doc.text(project.projectKey, textXPosition, 50);
					this.generatePdfBody(project, doc);
				}
				img.onerror = (e) => { 
					console.error("Image failed to load");
					// Display an error message to the user 
					this.$notify({
						message: ` ${this.$t('errors.companyPhoto')}`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				};
			}
		},
    generatePdfBody(project, doc) {
      doc.setFontSize(10);

      // Prepare the headers
      const headers = [
        [
          { content: 'Tól', styles: { halign: 'left' } },
          { content: 'Fjöldi', styles: { halign: 'center' } },
          { content: 'Einingarverð', styles: { halign: 'center' } },
          { content: 'Verð', styles: { halign: 'right' } },
          { content: 'Verð m/vsk', styles: { halign: 'right' } }
        ]
      ];
      // Prepare the data
      const body = [];
      const footer = [];
      const colSpan = headers[0].length;

      // Start with an empty line to separate from the table body from the header a bit better
      body.push([
        {
          content: '',
          colSpan: colSpan,
          styles: { halign: 'left', cellPadding: 0.1 }
        }
      ]);

      const groupedByTools = project.reservations.reduce((acc, reservation) => {
        if (!acc[reservation.toolId]) {
          acc[reservation.toolId] = {
            tool: reservation.tool,
            reservations: []
          };
        }
        acc[reservation.toolId].reservations.push(reservation);
        return acc;
      }, {});

      const toolsArray = Object.keys(groupedByTools).map(toolId => groupedByTools[toolId]);

      toolsArray.forEach(tool => {
        // Add a tool name row as a section header
        var toolBody = [];
        var printTool = false;
        toolBody.push([
          {
            content: tool.tool.name,
            colSpan: colSpan,
            styles: { halign: 'left' }
          }
        ]);

        tool.reservations.forEach(reservation => {
          if (reservation.totalPrice !== 0) {
            printTool = true;
            const dateDisplay = this.setupDateRange(reservation.reservedTimestamp, reservation.unreservedTimestamp);
            const unitPrice = this.formatNumber(reservation.dailyPrice.toFixed(2));
            const quantity = reservation.quantity !== null ? reservation.quantity.toString() : '1';
            const priceCalculation = this.formatNumber(reservation.totalPrice.toFixed(2)) + ' kr';
            const priceWithVAT = this.formatNumber(reservation.totalPriceWithVAT.toFixed(2)) + ' kr';

            toolBody.push([
              { content: `    ${dateDisplay}`, styles: { halign: 'left' } },
              { content: quantity, styles: { halign: 'center' } },
              { content: unitPrice, styles: { halign: 'center' } },
              { content: priceCalculation, styles: { halign: 'right' } },
              { content: priceWithVAT, styles: { halign: 'right' } }
            ]);
          }
        });

        if (printTool) {
          toolBody.push([
            {
              content: '',
              colSpan: colSpan,
              styles: { halign: 'left' }
            }
          ]);
          body.push(...toolBody);
        }
      });

      // Add the total amounts
      const revenueWithoutVAT = this.formatNumber(project.totalAmount.toFixed(2)) + ' kr';
      const totalWithVAT = this.formatNumber(project.totalAmountWithVAT.toFixed(2)) + ' kr';

      // This uses a customized colSpan to make sure rev and total is displayed in the correct columns
      footer.push([
        { content: 'Heildarupphæð', colSpan: 3, styles: { halign: 'left', fontStyle: 'bold' } },
        { content: revenueWithoutVAT, styles: { halign: 'right' } },
        { content: totalWithVAT, styles: { halign: 'right' } }
      ]);

      // Generate the table using autoTable
      autoTable(doc, {
        head: headers,
        body: body,
        foot: footer,
        startY: 70, // Adjust starting Y position based on your layout
        theme: 'plain', // Use 'plain' for no borders
        styles: {
          textColor: 0,
        },
        headStyles: {
          cellPadding: 3,
          fillColor: [255, 255, 255],
          fontStyle: 'bold',
          lineWidth: {
            top: 0.2,
            bottom: 0.2
          },
          lineColor: [0, 0, 0],
        },
        bodyStyles: {
          cellPadding: 1,
          fontSize: 10,
        },
        footStyles: {
          fillColor: [255, 255, 255],
          lineWidth: {
            top: 0.2,
          },
          lineColor: [0, 0, 0],
        },
        showHead: 'firstPage',
        showFoot: 'lastPage',
        columnStyles: {
          0: { cellWidth: 54 }, // Tól
          1: { cellWidth: 16 }, // Fjöldi
          2: { cellWidth: 30 }, // Einingarverð
          3: { cellWidth: 40 }, // Verð
          4: { cellWidth: 40 }  // Verð m/vsk
        }
      });

      // Save the PDF
      doc.save(`${project.projectName}.pdf`);
    },
		updateIsMobile() {
			this.isMobile = window.innerWidth <= 768;
		}
	}
};
</script>
<style scoped>
	.cost-report { 
		width: 100%; border-collapse: collapse;
	}
	.header-row, .project-row, .footer-row, .details {
		display: flex;
	}
	.header-row {
		border-bottom: 1px solid #ddd;
	}
	.footer-row {
		border-top: 1px solid #ddd;
	}
	.cell { 
		flex: 1; padding: 8px; text-align: left; cursor: pointer;
	} 
	.tool-row .cell { 
		cursor: default;
	}
	.calendar {
		padding-bottom: 15px;
	}
	.item-tool {
		padding-left: 20px;
		margin-bottom: 10px;
	}

	.item-tool-dates {
		padding-left: 40px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.item-tool-dates-amount {
		float: right;
		padding-right: 50px;
	}

	.download-button {
		width: 100%
	}

	.project-details { 
		display: block;
	}
	.newline { 
		display: block;
	}
</style>
